// External imports
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Form, Input, message, Row, Typography,
} from 'antd';
import {
  LockOutlined, UserOutlined,
} from '@ant-design/icons';

// Internal imports
import * as ROUTES from './Routes';
import { withFirebase } from './firebase';

const { Title } = Typography;

class LoginForm extends Component {

  componentDidMount() {
    const { firebase, history } = this.props;
    this.listener = firebase.onAuthUserListener(
      (authUser) => {
        /* If user is already logged in, redirect to home */
        if (authUser) history.push(ROUTES.HOME);
      }
    );
  }

  handleEmailSignIn = (val) => {
    const { firebase, history } = this.props;
    const { email } = val;
    const { password } = val;
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => history.push(ROUTES.HOME))
      .catch(() => {
        message.error('Login was not successful. Please input valid login details.');
      });
  };

  render() {
    return (
      <div>
        <div>
          <Row justify="center" style={{ marginTop: '128px' }}>
            <img src="./logo192.png"  alt="flair logo" />
          </Row>
          <Row justify="center" style={{ marginTop: '32px' }}>
            <Title level={2}>Sign in</Title>
          </Row>
          <Row justify="center" style={{ marginTop: '16px' }}>
            <Form
              onFinish={this.handleEmailSignIn}
              className="login-form"
              name="normal_login"
              initialValues={{
                size: 'large',
              }}
              size="large"
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                  autoFocus
                />
              </Form.Item>
              <Form.Item
                name="password"
                hasFeedback
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <div style={{ float: "right " }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Sign in
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Row>
          <div className="error-message" />
        </div>
      </div>
    );
  }
}

export default withRouter(withFirebase(LoginForm));
