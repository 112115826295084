// External imports
import React from 'react';
import {Checkbox, Progress} from 'antd';
import {
    CheckCircleTwoTone, CloseCircleTwoTone,
} from '@ant-design/icons';

// ===============================
//           OCR logic
// ===============================
export const OCR_METHODS = {
    TESSERACT: 'TESSERACT',
    GOOGLE_VISION: 'GOOGLE_VISION',
}

// ===============================
//         Business logic
// ===============================
export const MENU_OPTIONS = {
    HOME: 0,
    SUBMIT_SHIPMENT: 1,
    GET_SHIPMENT: 2,
}

export const PRODUCTION_LOCATIONS = {
    DONGGUAN: 'Dongguan'
}

export const DISTRIBUTION_LOCATIONS = {
    CHINA: 0,
    IRVINE: 1,
}

export const COUNTRIES = {
    END_CUSTOMER: 'End customer',
    OTHER: 'Other',
    KUWAIT: 'Kuwait',
    EGYPT: 'Egypt',
    AUSTRALIA: 'Australia',
    FINLAND: 'Finland',
    VIETNAM: 'Vietnam',
    PHILIPPINES: 'Philippines',
    BELGIUM: 'Belgium',
    LAOS: 'Laos',
    COSTA_RICA: 'Costa Rica',
    SWITZERLAND: 'Switzerland',
    SINGAPORE: 'Singapore',
    ROMANIA: 'Romania',
    NETHERLANDS: 'Netherlands',
    PAKISTAN: 'Pakistan',
    CHINA: 'China',
    INDONESIA: 'Indonesia',
    JAPAN: 'Japan',
    DENMARK: 'Denmark',
    ISRAEL: 'Israel',
    FRANCE: 'France',
    SOUTH_KOREA: 'South Korea',
    CYPRUS: 'Cyprus',
    HUNGARY: 'Hungary',
    BRUNEI: 'Brunei',
    RUSSIA: 'Russia',
    TAIWAN: 'Taiwan',
    GERMANY: 'Germany',
    INDIA: 'India',
    SAUDI_ARABIA: 'Saudi Arabia',
    THAILAND: 'Thailand',
    SLOVAKIA: 'Slovakia',
    MALAYSIA: 'Malaysia',
    ENGLAND: 'England',
    UKRAINE: 'Ukraine',
    CZECH_REPUBLIC: 'Czech Republic',
    MEXICO: 'Mexico',
    NEW_ZEALAND: 'New Zealand',
    USA: 'USA',
    CANADA: 'Canada',
    GUATEMALA: 'Guatemala',
    PANAMA: 'Panama',
    ARGENTINA: 'Argentina',
    COLOMBIA: 'Colombia',
    SOUTH_AFRICA: 'South Africa',
    GUAM: 'Guam',
    LATVIA: 'Latvia',
    SLOVENIA: 'Slovenia'
}

export const DISTRIBUTION_LIST = {
    CHINA: {
        0: {
            COUNTRY: COUNTRIES.OTHER,
            QUICKBOOKS_NAME: "Other",
            COMPANY: "Other - If Not Listed Below",
        },
        1: {
            COUNTRY: COUNTRIES.KUWAIT,
            QUICKBOOKS_NAME: "Abdullah Alajmi",
            COMPANY: "Brew Lab General",
        },
        2: {
            COUNTRY: COUNTRIES.EGYPT,
            QUICKBOOKS_NAME: "Ahmed Abdallah",
            COMPANY: "Elite Import and Export",
        },
        3: {
            COUNTRY: COUNTRIES.FINLAND,
            QUICKBOOKS_NAME: "Brandon Trujillo",
            COMPANY: "Mukti Oy",
        },
        4: {
            COUNTRY: COUNTRIES.SINGAPORE,
            QUICKBOOKS_NAME: "Choo Wee Ang",
            COMPANY: "DrinksPeople",
        },
        5: {
            COUNTRY: COUNTRIES.PHILIPPINES,
            QUICKBOOKS_NAME: "Daniel Cunanan",
            COMPANY: "Method Brewers",
        },
        6: {
            COUNTRY: COUNTRIES.BELGIUM,
            QUICKBOOKS_NAME: "Dany Meeuwissen",
            COMPANY: "The God Shot",
        },
        7: {
            COUNTRY: COUNTRIES.LAOS,
            QUICKBOOKS_NAME: "David Viron",
            COMPANY: "Dam Dam",
        },
        8: {
            COUNTRY: COUNTRIES.COSTA_RICA,
            QUICKBOOKS_NAME: "Donny Chou",
            COMPANY: "Musa Lab",
        },
        9: {
            COUNTRY: COUNTRIES.SWITZERLAND,
            QUICKBOOKS_NAME: "ECOCAFE SA",
            COMPANY: "Damien Fortun",
        },
        10: {
            COUNTRY: COUNTRIES.ROMANIA,
            QUICKBOOKS_NAME: "Eduard Costea",
            COMPANY: "Just Coffee SRL",
        },
        11: {
            COUNTRY: COUNTRIES.NETHERLANDS,
            QUICKBOOKS_NAME: "Espresso Expert",
            COMPANY: "Waqar Khan",
        },
        12: {
            COUNTRY: COUNTRIES.PAKISTAN,
            QUICKBOOKS_NAME: "Faisal S. Khan",
            COMPANY: "Mokha Trading Company",
        },
        13: {
            COUNTRY: COUNTRIES.CHINA,
            QUICKBOOKS_NAME: "Gang Yu",
            COMPANY: "Gang Yu",
        },
        14: {
            COUNTRY: COUNTRIES.PHILIPPINES,
            QUICKBOOKS_NAME: "Jackie Torrijos",
            COMPANY: "Manila Espresso",
        },
        15: {
            COUNTRY: COUNTRIES.INDONESIA,
            QUICKBOOKS_NAME: "JHONI KUSNO",
            COMPANY: "Otten",
        },
        16: {
            COUNTRY: COUNTRIES.PHILIPPINES,
            QUICKBOOKS_NAME: "Keith Yu",
            COMPANY: "1933 Coffee Co.",
        },
        17: {
            COUNTRY: COUNTRIES.JAPAN,
            QUICKBOOKS_NAME: "Kento Yanagida",
            COMPANY: "Franklin Inc.",
        },
        18: {
            COUNTRY: COUNTRIES.DENMARK,
            QUICKBOOKS_NAME: "Kim Jeppesen",
            COMPANY: "Third Wave",
        },
        19: {
            COUNTRY: COUNTRIES.ISRAEL,
            QUICKBOOKS_NAME: "Lior Sneer",
            COMPANY: "B.A Kedma Coffee",
        },
        20: {
            COUNTRY: COUNTRIES.FRANCE,
            QUICKBOOKS_NAME: "Mathieu Guillon",
            COMPANY: "MaxiCoffee",
        },
        21: {
            COUNTRY: COUNTRIES.PHILIPPINES,
            QUICKBOOKS_NAME: "Mayumi Yanga",
            COMPANY: "Etica Lifestyle",
        },
        22: {
            COUNTRY: COUNTRIES.SOUTH_KOREA,
            QUICKBOOKS_NAME: "MKNARA,CO.,LTD",
            COMPANY: "MKNARA,CO.,LTD",
        },
        23: {
            COUNTRY: COUNTRIES.CYPRUS,
            QUICKBOOKS_NAME: "Panicos Papamichael",
            COMPANY: "BDM PLS ltd.",
        },
        24: {
            COUNTRY: COUNTRIES.HUNGARY,
            QUICKBOOKS_NAME: "Peter Nemeth",
            COMPANY: "Peter Nemeth",
        },
        25: {
            COUNTRY: COUNTRIES.BRUNEI,
            QUICKBOOKS_NAME: "Sashah Razali",
            COMPANY: "Sashah Razali",
        },
        26: {
            COUNTRY: COUNTRIES.RUSSIA,
            QUICKBOOKS_NAME: "Sergei Tcyvian",
            COMPANY: "Sergei Tcyvian",
        },
        27: {
            COUNTRY: COUNTRIES.TAIWAN,
            QUICKBOOKS_NAME: "Shing Liu",
            COMPANY: "Lancer Trading",
        },
        28: {
            COUNTRY: COUNTRIES.DENMARK,
            QUICKBOOKS_NAME: "Stefan Gauglitz",
            COMPANY: "Coffee24",
        },
        29: {
            COUNTRY: COUNTRIES.INDIA,
            QUICKBOOKS_NAME: "Suhas Dwarakanath",
            COMPANY: "Benki Brewing",
        },
        30: {
            COUNTRY: COUNTRIES.SAUDI_ARABIA,
            QUICKBOOKS_NAME: "Talal Madkhali",
            COMPANY: "Talal Madkhali",
        },
        31: {
            COUNTRY: COUNTRIES.THAILAND,
            QUICKBOOKS_NAME: "Thitimon Suboonsan",
            COMPANY: "JCoffee",
        },
        32: {
            COUNTRY: COUNTRIES.NETHERLANDS,
            QUICKBOOKS_NAME: "Timo Imanse",
            COMPANY: "BobPlaze",
        },
        33: {
            COUNTRY: COUNTRIES.SLOVAKIA,
            QUICKBOOKS_NAME: "Vladimir Sycev",
            COMPANY: "NajTrade",
        },
        34: {
            COUNTRY: COUNTRIES.MALAYSIA,
            QUICKBOOKS_NAME: "Woei Luen Lee",
            COMPANY: "Kinabalu Peak",
        },
        35: {
            COUNTRY: COUNTRIES.ENGLAND,
            QUICKBOOKS_NAME: "Yoav Goldner",
            COMPANY: "Doppio",
        },
        36: {
            COUNTRY: COUNTRIES.UKRAINE,
            QUICKBOOKS_NAME: "Yura Sirosh",
            COMPANY: "One Love",
        },
        37: {
            COUNTRY: COUNTRIES.CZECH_REPUBLIC,
            QUICKBOOKS_NAME: "Zdenek",
            COMPANY: "Lázeňská káva s.r.o.",
        },
        38: {
            COUNTRY: COUNTRIES.CHINA,
            QUICKBOOKS_NAME: "Zhou Fu Dong",
            COMPANY: "Adonis",
        },
        39: {
            COUNTRY: COUNTRIES.AUSTRALIA,
            QUICKBOOKS_NAME: "Lyndsay Mitchell",
            COMPANY: "Bombora Coffee & Water Supplies",
        },
        40: {
            COUNTRY: COUNTRIES.NEW_ZEALAND,
            QUICKBOOKS_NAME: "James Graham",
            COMPANY: "The Coffee Company",
        },
        41: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Slawek Janicki",
            COMPANY: "idrinkcoffee",
        },
        42: {
            COUNTRY: COUNTRIES.LATVIA,
            QUICKBOOKS_NAME: "Karlis Libeks",
            COMPANY: "Gemoss",
        },
        43: {
            COUNTRY: COUNTRIES.KUWAIT,
            QUICKBOOKS_NAME: "Mishari Almutairi",
            COMPANY: "Bazar Coffee Tools",
        },
        44: {
            COUNTRY: COUNTRIES.SLOVENIA,
            QUICKBOOKS_NAME: "Mitja Jarc",
            COMPANY: " CoffeTime S.P.",
        }
    },
    IRVINE: {
        0: {
            COUNTRY: COUNTRIES.END_CUSTOMER,
            QUICKBOOKS_NAME: "End customer",
            COMPANY: "End customer",
        },
        1: {
            COUNTRY: COUNTRIES.END_CUSTOMER,
            QUICKBOOKS_NAME: "Amazon",
            COMPANY: "Amazon",
        },
        2: {
            COUNTRY: COUNTRIES.END_CUSTOMER,
            QUICKBOOKS_NAME: "Walmart",
            COMPANY: "Walmart",
        },
        3: {
            COUNTRY: COUNTRIES.OTHER,
            QUICKBOOKS_NAME: "Other",
            COMPANY: "Other - If Not Listed Below",
        },
        4: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Aron Felkins",
            COMPANY: "Dreux Coffee",
        },
        5: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Blue Sail Coffee",
            COMPANY: "Blue Sail Coffee",
        },
        6: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Dan Sinkgraven",
            COMPANY: "Coffea Roasterie",
        },
        7: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Dave Montez",
            COMPANY: "Campfyre Vans",
        },
        8: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Denis Leblanc",
            COMPANY: "No6 Coffee Co.",
        },
        9: {
            COUNTRY: COUNTRIES.MEXICO,
            QUICKBOOKS_NAME: "Grupo Exploradores",
            COMPANY: "Jose Antonio",
        },
        10: {
            COUNTRY: COUNTRIES.GUATEMALA,
            QUICKBOOKS_NAME: "iCoffee Solutions",
            COMPANY: "Manuel Lara",
        },
        11: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "James Coffee Co.",
            COMPANY: "James Coffee Co.",
        },
        12: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Jonathan Crocker",
            COMPANY: "Landslide Coffee Company",
        },
        13: {
            COUNTRY: COUNTRIES.PANAMA,
            QUICKBOOKS_NAME: "JULIO ALFREDO VILLASMIL KUPFERSCHMID",
            COMPANY: "Infumas",
        },
        14: {
            COUNTRY: COUNTRIES.GUAM,
            QUICKBOOKS_NAME: "Justin Benavente",
            COMPANY: "Coffee Slut",
        },
        15: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Justin Crowder",
            COMPANY: "CAFUNE",
        },
        16: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Karen Lopez",
            COMPANY: "U-Roast It Coffee",
        },
        17: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Keri Elliott",
            COMPANY: "Elliot & Murrey Coffee Roasters",
        },
        18: {
            COUNTRY: COUNTRIES.ARGENTINA,
            QUICKBOOKS_NAME: "Martin Solari",
            COMPANY: "MAP Argentina",
        },
        19: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Nicole Gulewitsch",
            COMPANY: "Detour Coffee Roasters",
        },
        20: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Pier-Paul Fortin",
            COMPANY: "L'Atelieer Espresso",
        },
        21: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Prima, LLC",
            COMPANY: "Prima, LLC",
        },
        22: {
            COUNTRY: COUNTRIES.MEXICO,
            QUICKBOOKS_NAME: "Roberto Vazquez Rangel",
            COMPANY: "Roberto Vazquez Range",
        },
        23: {
            COUNTRY: COUNTRIES.COLOMBIA,
            QUICKBOOKS_NAME: "Sandra Patricia Quintero",
            COMPANY: "La Feria de la Greca",
        },
        24: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Scott Kobrick",
            COMPANY: "Kobrick's",
        },
        25: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Sean Donnelly",
            COMPANY: "Mighty Oak Roasters",
        },
        26: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Tal Mor",
            COMPANY: "Four Barrel Coffee",
        },
        27: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Thompson Owen",
            COMPANY: "Sweet Marias",
        },
        28: {
            COUNTRY: COUNTRIES.SOUTH_AFRICA,
            QUICKBOOKS_NAME: "Warren Familiar",
            COMPANY: "Euphoria Specialty Coffee",
        },
        29: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Amber Cooley",
            COMPANY: "Oak Hill Farm Coffee",
        },
        30: {
            COUNTRY: COUNTRIES.MEXICO,
            QUICKBOOKS_NAME: "Andres Rivera Pesquera",
            COMPANY: "Pave Cycling Cafe",
        },
        31: {
            COUNTRY: COUNTRIES.NEW_ZEALAND,
            QUICKBOOKS_NAME: "Ange Spori",
            COMPANY: "Coffee & Co",
        },
        32: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Donovan Kahler",
            COMPANY: "Beauty Boxx",
        },
        33: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Emily DeSanto",
            COMPANY: "La Colombe",
        },
        34: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Carl Martens",
            COMPANY: "Aventuron",
        },
        35: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Keri Elliot",
            COMPANY: "Elliot & Murrey",
        },
        36: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Mark Bouchett",
            COMPANY: "Homeport",
        },
        37: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Travis Mason",
            COMPANY: "Grit Coffee",
        },
        38: {
            COUNTRY: COUNTRIES.USA,
            QUICKBOOKS_NAME: "Magdelena Van Dusen",
            COMPANY: "Brio Coffeeworks",
        },
        39: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "David Bigelow",
            COMPANY: "Cherry Hill Coffee",
        },
        40: {
            COUNTRY: COUNTRIES.CANADA,
            QUICKBOOKS_NAME: "Darry Allen",
            COMPANY: "Elora Coffee Roaster",
        },
        41: {
            COUNTRY: COUNTRIES.ARGENTINA,
            QUICKBOOKS_NAME: "Martina Vigo",
            COMPANY: "Flat n White",
        }
    }
}

// ===============================
//         Boilerplate
// ===============================
export function deepCopy(dict) {
    return JSON.parse(JSON.stringify(dict));
}

// ===============================
//         Upload logic
// ===============================
export const FILE_STATUS = {
    UPLOADING: 'uploading',
    DONE: 'done',
    ERROR: 'error',
    REMOVED: 'removed',
};

export function isUploadingInProgress(fileList) {
    for (let i = 0; i < fileList.length; i += 1) {
        if (
            Object.prototype.hasOwnProperty.call(fileList[i], 'status')
            && fileList[i].status === 'uploading'
        ) return true;
    }
    return false;
}
