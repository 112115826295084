// External imports
import React, { Component } from 'react';
import {Layout} from 'antd';
import {Route, Switch} from 'react-router-dom';
import { compose } from 'recompose';

// Internal imports
import withAuthorization from './session/withAuthorization';
import * as ROUTES from './Routes';
import HomePage from "./HomePage";
import PostSKUPage from "./PostSKUPage";
import GetSKUPage from "./GetSKUPage";
import HeaderMenu from "./Menu";

const { Header, Content } = Layout;

class AppAuthenticated extends Component {
    render() {
        return (
            <Layout className="layout">
                <Header className="site-layout-header">
                    <HeaderMenu />
                </Header>
                <Content className="site-layout-content">
                    <Switch>
                        <Route path={ROUTES.POST_SHIPMENT} component={PostSKUPage}/>
                        <Route path={ROUTES.GET_SHIPMENT} component={GetSKUPage}/>
                        <Route path={ROUTES.HOME} component={HomePage}/>
                    </Switch>
                </Content>
            </Layout>
        );
    }
}

const condition = (authUser) => !!authUser;
export default compose(
    withAuthorization(condition),
)(AppAuthenticated);