export const bucketDirectories = {
    production: {
        videos: 'cord-videos-prod/',
        images: 'cord-images-prod/',
        models: 'cord-models-prod/',
    },
    development: {
        videos: 'cord-videos-dev/',
        images: 'cord-images-dev/',
        models: 'cord-models-dev/',
    },
};

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MSG_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}