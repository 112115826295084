import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Typography, Button } from 'antd';
import {withFirebase} from "./firebase";

const { Title } = Typography;

function HomePage(props) {

    const logout = () => {
        const { firebase, history } = props;
        firebase.doSignOut().then(() => {
            history.push('/login');
            window.location.reload();
        });
    };

    return (
        <div>
            <Row justify="center" style={{ marginTop: '64px', marginBottom: '64px'}}>
                    <img src="./logo192.png"  alt="flair logo" />
            </Row>
            <Row justify="center" style={{ marginBottom: '64px'}}>
                <Title level={3}>Welcome to Flair Espresso</Title>
            </Row>
            <Row justify="center">
                <Button onClick={() => logout()} type="primary" size="large">
                    Sign Out
                </Button>
            </Row>
        </div>
    );
}

export default withFirebase(withRouter(HomePage));
