// External imports
import axios from 'axios';
import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import jwtDecode from 'jwt-decode';

// Internal imports
import { BASE_API_URL } from '../APIConstants';
import { bucketDirectories, firebaseConfig } from '../secrets';

const TOKEN_REFRESH_INTERVAL_M = 30;
const TOKEN_REFRESH_INTERVAL_MS = TOKEN_REFRESH_INTERVAL_M * 60000;

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    app.analytics();
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();

    this.refreshTimer = setInterval(this.refreshTokenFromIntervalExpiry, TOKEN_REFRESH_INTERVAL_MS);
  }

  getUserId() {
    if (this.auth.currentUser) {
      return this.auth.currentUser.uid;
    }
    return null;
  }

  getCurrentIdToken = () => axios.defaults.headers.common.Authorization

  getCurrentIdTokenExpiry = (idToken) => jwtDecode(idToken).exp

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  sendRequest(authUser) {
    if (this.auth.currentUser) {
      this.auth.currentUser
        .getIdToken(false)
        .then((idToken) => {
          axios.defaults.headers.common.Authorization = idToken;
        })
        .catch((error) => {
          this.response = 'Error getting auth token';
        });
    } else {
      axios
        .get(BASE_API_URL, { withCredentials: true })
        .then((res) => {
          this.response = res.data.message;
        })
        .catch((error) => {
          this.response = error;
        });
    }
  }

  refreshTokenFromIntervalExpiry = () => this.auth.onAuthStateChanged((authUser) => {
    this.setAuthHeader(authUser, true);
  })

  refreshTokenIfNearExpiry(idToken, authUser) {
    const idTokenExpiry = this.getCurrentIdTokenExpiry(idToken);
    const currentTimeStamp = Math.round(new Date().getTime() / 1000);
    if ((idTokenExpiry - currentTimeStamp) / 60 < TOKEN_REFRESH_INTERVAL_M) {
      this.setAuthHeader(authUser, true);
    } else {
      this.setAuthHeader(authUser, false);
    }
  }

  setAuthHeader = (authUser, refresh) => {
    if (authUser) {
      authUser.getIdToken(refresh).then((idToken) => {
        axios.defaults.headers.common.Authorization = idToken;
      }).catch(() => {});
    } else {
      window.location.replace('/login');
    }
  }

  onAuthUserListener = (next) => this.auth.onAuthStateChanged((authUser) => {
    if (authUser) {
      const idToken = this.getCurrentIdToken();
      if (idToken) {
        this.refreshTokenIfNearExpiry(idToken, authUser);
      } else {
        this.setAuthHeader(authUser, false);
      }

      this.user(authUser.uid)
        .once('value')
        .then((snapshot) => {
          const dbUser = snapshot.val();
          next({
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          });
        });
    }
  });

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  doImageDeletion = (image_hash) => {
    const fileName = image_hash;
    const imageDirectory = bucketDirectories[process.env.NODE_ENV].images;
    return this.storage.ref().child(`${imageDirectory}/${this.getUserId()}/${fileName}`).delete();
  }

  doImageUpload = (file) => {
    const fileName = file.image_hash;
    const imageDirectory = bucketDirectories[process.env.NODE_ENV].images;
    return this.storage.ref().child(`${imageDirectory}/${this.getUserId()}/${fileName}`).put(file);
  }

  getDownloadLink = (path) => this.storage.ref().child(path).getDownloadURL()
      .then((url) => url)
      .catch(() => {})
}

export default Firebase;
