import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {Menu} from 'antd';

import * as ROUTES from "./Routes";
import {MENU_OPTIONS} from "./AppConstants";

function getRootPath() {
    let path = window.location.pathname;
    path = path.substring(1, path.length);
    if (path.indexOf('/') > 0) {
        path = path.substring(0, path.indexOf('/'));
    }
    path = `/${path}`;
    return path;
}

function getSelectedKey () {
    const path = getRootPath();
    switch (path) {
        case ROUTES.HOME:
            return '0';
        case ROUTES.POST_SHIPMENT:
            return '1';
        case ROUTES.GET_SHIPMENT:
            return '2';
        default:
            return null;
    }
}

function HeaderMenu() {
    const [page, setPage] = useState(getSelectedKey());
    return (
        <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[page]}
        >
            <Menu.Item key={MENU_OPTIONS.HOME} onClick={() => setPage('0')}>
                <Link to={ROUTES.HOME}>
                  <span style={{fontSize: '14px', fontWeight: 'bold'}}>
                      Home
                  </span>
                </Link>
            </Menu.Item>
            <Menu.Item key={MENU_OPTIONS.SUBMIT_SHIPMENT} onClick={() => setPage('1')}>
                <Link to={ROUTES.POST_SHIPMENT}>
                  <span style={{fontSize: '14px', fontWeight: 'bold'}}>
                      Submit
                  </span>
                </Link>
            </Menu.Item>
            <Menu.Item key={MENU_OPTIONS.GET_SHIPMENT} onClick={() => setPage('2')}>
                <Link to={ROUTES.GET_SHIPMENT}>
                    <span style={{fontSize: '14px', fontWeight: 'bold'}}>
                        Get
                    </span>
                </Link>
            </Menu.Item>
        </Menu>
    );
}

export default HeaderMenu;