// External imports
import React, {Component} from 'react';
import { Upload, Table, Button, Row, Col, Progress } from 'antd';
import {v4 as uuidv4} from 'uuid';

import {withFirebase} from './firebase';
import withAuthorization from './session/withAuthorization';
import {deepCopy, FILE_STATUS } from "./AppConstants";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";

const {Dragger} = Upload;
const {Column} = Table;

const formatImageForInsert = (file, firebaseRef) => ({
    title: file.name,
    file_link: firebaseRef.fullPath,
    file_size: file.size,
    file_type: file.type,
    serial_no: null,
    image_hash: firebaseRef.name,
    status: FILE_STATUS.DONE,
    progress: 100,
});

const formatFileDuringUpload = (file) => ({
    title: file.name,
    file_link: null,
    file_size: file.size,
    file_type: file.type,
    serial_no: null,
    image_hash: file.image_hash,
    status: FILE_STATUS.UPLOADING,
    progress: file.progress,
});

class UploadToGCP extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            imageFileList: this.props.fileList,
        };
    }

    selectDatasetItems = (selectedRowKeys) => this.setState({selectedRowKeys});

    removeFromDataset = () => {
        const {firebase} = this.props;
        const removeRows = new Set(this.state.selectedRowKeys);
        const imageFileList = this.state.imageFileList.filter(
            (item) => !removeRows.has(item.image_hash),
        );
        this.state.selectedRowKeys.forEach((row) => {
            firebase.doImageDeletion(row);
        });
        this.setState({imageFileList, selectedRowKeys: []}, () => {
            this.props.setFileList(imageFileList)
        });
    };

    // ===============================
    //         Upload logic
    // ===============================
    insertImageToDB = (file, firebaseRef) => {
        const fileListFile = formatImageForInsert(file, firebaseRef);
        const {imageFileList} = this.state;
        let _imageFileList = deepCopy(imageFileList);
        const arrayIndex = imageFileList.findIndex(_file => _file.image_hash === file.image_hash);
        _imageFileList[arrayIndex] = fileListFile
        this.setState({imageFileList: _imageFileList}, () => {
            this.props.setFileList(_imageFileList)
        })
    }

    customUpload = ({
                        onError, onProgress, onSuccess, file,
                    }) => {
        const imageHash = uuidv4();
        file.uid = imageHash;
        file.image_hash = imageHash;
        const uploadTask = this.props.firebase.doImageUpload(file);
        uploadTask.on('state_changed',
            ((snapshot) => {
                const {imageFileList} = this.state;
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress({percent: progress});
                file.progress = progress;
                const fileListFile = formatFileDuringUpload(file);
                if (imageFileList.some(_file => _file.image_hash === fileListFile.image_hash)) {
                    let _imageFileList = deepCopy(imageFileList);
                    const arrayIndex = imageFileList.findIndex(_file => _file.image_hash === fileListFile.image_hash);
                    _imageFileList[arrayIndex] = fileListFile
                    this.setState({imageFileList: _imageFileList}, () => {
                        this.props.setFileList(_imageFileList)
                    })
                } else {
                    this.setState({imageFileList: [fileListFile, ...this.state.imageFileList]}, () => {
                        this.props.setFileList(this.state.imageFileList)
                    });
                }
            }), ((error) => {
                onError(error, file);
            }), (() => {
                    this.insertImageToDB(file, uploadTask.snapshot.ref);
                    onSuccess(null, file);
                }
            ));
    };

    render() {
        const {fileList, singleUpload, pagination} = this.props;
        const {selectedRowKeys} = this.state;
        const rowSelection = {selectedRowKeys, onChange: this.selectDatasetItems};
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Dragger
                            multiple={!singleUpload}
                            accept="image/*"
                            customRequest={this.customUpload}
                            showUploadList={false}
                            size="small"
                        >
                            <p className="ant-upload-text">Click here to upload image{!singleUpload && 's'}</p>
                        </Dragger>
                    </Col>
                </Row>
                <Row style={{marginTop: '8px'}}>
                    <Col span={24}>
                        {fileList && fileList.length > 0 && (
                            <Table
                                rowKey="image_hash"
                                rowSelection={rowSelection}
                                dataSource={fileList}
                                pagination={pagination ? {defaultPageSize: 10} : false}
                                size="small"
                            >
                                <Column
                                    title="File"
                                    dataIndex="title"
                                />
                                <Column
                                    title=''
                                    render={item => {
                                        if (item.serial_no) {
                                            return (
                                                item.serial_no.map((serialNo) => (
                                                    <span key={serialNo}>
                                                        {serialNo}<br/>
                                                    </span>
                                                ))
                                            )
                                        }
                                    }}
                                />
                                <Column
                                    title=''
                                    render={item => {
                                        switch (item.status) {
                                            case FILE_STATUS.DONE:
                                                return (
                                                    <span>
                                                        <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize: '16px'}}/>
                                                    </span>
                                                );
                                            case FILE_STATUS.UPLOADING:
                                                return (
                                                    <span>
                                                        <Progress type="circle" percent={item.progress} width={16} showInfo={false}/>
                                                    </span>
                                                );
                                            case FILE_STATUS.ERROR:
                                                return (
                                                    <span>
                                                        <CloseCircleTwoTone twoToneColor="#eb2f96" style={{fontSize: '16px'}}/>
                                                    </span>
                                                );
                                            default:
                                                return (
                                                    <span>
                                                        <CloseCircleTwoTone twoToneColor="#eb2f96" style={{fontSize: '16px'}}/>
                                                    </span>
                                                );
                                        }
                                    }}
                                />
                            </Table>
                        )}
                        {selectedRowKeys && selectedRowKeys.length > 0 && (
                            <Button type="danger" onClick={this.removeFromDataset}>
                                Delete
                            </Button>
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}

const condition = (authUser) => !!authUser;
export default (withAuthorization(condition)(withFirebase(UploadToGCP)));
