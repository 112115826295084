import React, {useState} from 'react';
import {Row, Radio, Typography, Col, Button, message, Input} from "antd";
import UploadToGCP from "./UploadToGCP";
import {isUploadingInProgress, OCR_METHODS} from "./AppConstants";
import axios from "axios";
import {GENERAL_OPERATIONS_API_URL, SHIPMENT_OPERATIONS_API_URL} from "./APIConstants";
import withAuthorization from "./session/withAuthorization";

const {Text} = Typography;

function GetSKUPage(props) {
    const [getMode, setGetMode] = useState("0")
    const [processingOCR, setProcessingOCR] = useState(false)
    const [invoicePicture, setInvoicePicture] = useState([])
    const [serialPicture, setSerialPicture] = useState([])
    const [invoiceNumber, setInvoiceNumber] = useState(null)
    const [serialNumber, setSerialNumber] = useState(null)
    const [fetchingDBDetails, setFetchingDBDetails] = useState(false)
    const [DBPayload, setDBPayload] = useState(null)

    // ===============================
    //          Upload logic
    // ===============================
    const uploadInProgress =
        isUploadingInProgress(invoicePicture)
        || isUploadingInProgress(serialPicture);

    // ===============================
    //  OCR processing logic for invoice number
    // ===============================
    const runOCRForInvoiceNumber = () => {
        message.info('Processing image')
        const data = packageInvoiceNumberForOCR()
        setProcessingOCR(true)
        axios.post(`${GENERAL_OPERATIONS_API_URL}/ocr`, data)
            .then((res) => {
                const _res = res.data[0];
                _res.ocr_str.forEach((ocrStr) => {
                    if (ocrStr.length === 5 && !isNaN(ocrStr)) setInvoiceNumber(ocrStr)
                })
                message.success('Image processed')
                setProcessingOCR(false)
            }).catch(() => {
        });
    }

    const packageInvoiceNumberForOCR = () => {
        return {
            ocr_method: OCR_METHODS.GOOGLE_VISION,
            images: getInvoiceNumberImages()
        }
    }

    const getInvoiceNumberImages = () => {
        const images = []
        invoicePicture.forEach((image) => {
            images.push({
                image_hash: image.image_hash,
                file_link: image.file_link,
            })
        })
        return images
    }

    const getInvoiceDBDetails = () => {
        message.info('Fetching DB details')
        const data = packageInvoiceNumberForDB()
        setFetchingDBDetails(true)
        axios.post(`${SHIPMENT_OPERATIONS_API_URL}/read`, data)
            .then((res) => {
                if(res.data.length > 0) {
                    setDBPayload(parseDBPayload(res.data))
                    message.success('DB details fetched')
                } else {
                    message.error('DB details not found')
                }
                setFetchingDBDetails(false)
            }).catch(() => {
        });
    }

    const packageInvoiceNumberForDB = () => {
        return {
            query_type: "invoice_number",
            invoice_number: invoiceNumber,
        }
    }

    // ===============================
    //  OCR processing logic for serial number
    // ===============================
    const runOCRForSerialNumber = () => {
        message.info('Processing image')
        const data = packageSerialNumberForOCR()
        setProcessingOCR(true)
        axios.post(`${GENERAL_OPERATIONS_API_URL}/ocr`, data)
            .then((res) => {
                const _res = res.data;
                const _serialNumber = []
                const serialNumbers = parseSerialNumbers(_res)
                serialPicture.forEach((file) => {
                    file.serial_no = serialNumbers[file.image_hash]
                    _serialNumber.push(file)
                })
                setSerialNumber(_serialNumber[0].serial_no)
                message.success('Images processed')
                setProcessingOCR(false)
            }).catch(() => {
        });
    }

    const packageSerialNumberForOCR = () => {
        return {
            ocr_method: OCR_METHODS.GOOGLE_VISION,
            images: getSerialNumberImages()
        }
    }

    const getSerialNumberImages = () => {
        const images = []
        serialPicture.forEach((image) => {
            images.push({
                image_hash: image.image_hash,
                file_link: image.file_link,
            })
        })
        return images
    }

    const parseSerialNumbers = (res) => {
        const serialNumbers = {}
        res.forEach(element => {
            element.ocr_str.forEach(resOCRStr => {
                resOCRStr = resOCRStr.trim()
                if (isValidSerialNumber(resOCRStr)) {
                    serialNumbers[element.image_hash] = resOCRStr
                }
            })
        });
        return serialNumbers
    }

    const isValidSerialNumber = (serialNo) => {
        const letters = serialNo[0] + serialNo[1]
        const numbers = serialNo[2] + serialNo[3] + serialNo[4] + serialNo[5]
        return letters.match(/^[A-Za-z]+$/)
            && !isNaN(numbers);
    }

    const getSerialNumberDBDetails = () => {
        message.info('Fetching DB details')
        const data = packageSerialNumberForDB()
        setFetchingDBDetails(true)
        axios.post(`${SHIPMENT_OPERATIONS_API_URL}/read`, data)
            .then((res) => {
                if(res.data.length > 0)  {
                    setDBPayload(parseDBPayload(res.data))
                    message.success('DB details fetched')
                } else {
                    message.error('DB details not found')
                }
                setFetchingDBDetails(false)
            }).catch(() => {
        });
    }

    const packageSerialNumberForDB = () => {
        return {
            query_type: "serial_number",
            serial_number: serialNumber,
        }
    }

    // ===============================
    //         Parse DB payload
    // ===============================
    const parseDBPayload = (payload) => {
        return {
            "invoice_number": payload[0].invoice_number,
            "invoice_picture_link": payload[0].invoice_picture_link,
            "destination": JSON.parse(payload[0].destination),
            "serial_numbers": payload.map((tuple) => tuple.serial_number),
            "created_at": payload[0].created_at,
            "created_by": payload[0].user_hash
        }
    }

    const displayLink = () => {
        const link = DBPayload.invoice_picture_link
        props.firebase.getDownloadLink(link).then((url) => {
            window.open(url)
        })
    }

    return (
        <>
            <Row style={{marginTop: '16px'}}>
                <Text strong>Get by</Text>
            </Row>
            <Row>
                <Radio.Group value={getMode} disabled={uploadInProgress || processingOCR}>
                    <Radio.Button value="0" onClick={() => setGetMode("0")}>Invoice number</Radio.Button>
                    <Radio.Button value="1" onClick={() => setGetMode("1")}>Serial number</Radio.Button>
                </Radio.Group>
            </Row>
            <Row style={{marginTop: '32px'}}>
                {getMode === "0" &&
                <>
                    <Col span={24}>
                        <Text strong>Upload image of invoice number</Text>
                        <UploadToGCP
                            fileList={invoicePicture}
                            setFileList={(picture) => setInvoicePicture(picture)}
                            singleUpload
                            pagination={false}
                        />
                    </Col>
                    <Row>
                        <Text strong>OR type in invoice number</Text>
                        <Input value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
                    </Row>
                </>
                }
                {getMode === "1" &&
                <>
                    <Col span={24}>
                        <Text strong>Upload image of serial number</Text>
                        <UploadToGCP
                            fileList={serialPicture}
                            setFileList={(picture) => setSerialPicture(picture)}
                            singleUpload
                            pagination={false}
                        />
                    </Col>
                    <Row>
                        <Text strong>OR type in serial number</Text>
                        <Input value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                    </Row>
                </>
                }
            </Row>
            {getMode === "0" &&
            <>
                <Row justify="space-between" style={{marginTop: '4px'}}>
                    <Col span={12}>
                        <Text strong>Invoice number:</Text> {invoiceNumber}
                    </Col>
                    <Col span={12}>
                        <div style={{float: 'right '}}>
                            {invoicePicture.length > 0 &&
                            <Button
                                onClick={() => runOCRForInvoiceNumber()}
                                loading={processingOCR}
                                disabled={uploadInProgress}
                            >
                                Run OCR
                            </Button>
                            }
                        </div>
                    </Col>
                </Row>
                {invoiceNumber &&
                <Row style={{marginTop: '16px'}} justify="center">
                    <Col span={24}>
                        <Button type="primary" onClick={() => getInvoiceDBDetails()} loading={fetchingDBDetails} block>
                            Search database for invoice {invoiceNumber}
                        </Button>
                    </Col>
                </Row>
                }
            </>
            }
            {getMode === "1" &&
            <>
                <Row justify="space-between" style={{marginTop: '4px'}}>
                    <Col span={12}>
                        <Text strong>Serial number:</Text> {serialNumber}
                    </Col>
                    <Col span={12}>
                        <div style={{float: 'right '}}>
                            {serialPicture.length > 0 &&
                            <Button
                                onClick={() => runOCRForSerialNumber()}
                                loading={processingOCR}
                                disabled={uploadInProgress}
                            >
                                Run OCR
                            </Button>
                            }
                        </div>
                    </Col>
                </Row>
                {serialNumber &&
                <Row style={{marginTop: '16px'}} justify="center">
                    <Col span={24}>
                        <Button type="primary" onClick={() => getSerialNumberDBDetails()} loading={fetchingDBDetails} block>
                            Search database for serial number {serialNumber}
                        </Button>
                    </Col>
                </Row>
                }
            </>
            }
            {DBPayload &&
                <div style={{ marginTop: '16px'}}>
                    <Row>
                        <Text strong>Invoice number:&nbsp;</Text>{DBPayload.invoice_number}
                    </Row>
                    <Row>
                        <Text strong>Created at:&nbsp;</Text>{DBPayload.created_at} GMT
                    </Row>
                    <Row>
                        <Text strong>Created by:&nbsp;</Text>{DBPayload.created_by}
                    </Row>
                    <Row>
                        <Text strong>Destination company:&nbsp;</Text>{DBPayload.destination.COMPANY}
                    </Row>
                    <Row>
                        <Text strong>Destination country:&nbsp;</Text>{DBPayload.destination.COUNTRY}
                    </Row>
                    <Row>
                        <Text strong>Destination quickbooks:&nbsp;</Text>{DBPayload.destination.QUICKBOOKS_NAME}
                    </Row>
                    <Row>
                        <Text strong>Serial numbers:&nbsp;</Text>{DBPayload.serial_numbers.map((no) => <p key={no}>{no};&nbsp;</p>)}
                    </Row>
                    <Row>
                        <Text strong>Invoice picture:&nbsp;</Text><Button onClick={() => displayLink()}>Click to display</Button>
                    </Row>
                </div>
            }
            <Row justify="center" style={{ marginTop: '64px'}}>
                <Col span={24}>
                    <Button type="primary" key="console" onClick={() => window.location.reload(false)} block>
                        Start Over
                    </Button>
                </Col>
            </Row>
        </>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(GetSKUPage);
