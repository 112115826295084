import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import * as ROUTES from './Routes'
import withAuthentication from "./session/withAuthentication";
import LoginPage from "./LoginPage";
import AppAuthenticated from "./AppAuthenticated";

import 'antd/dist/antd.css';
import './index.css';

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={ROUTES.LOGIN} component={LoginPage}/>
                </Switch>
                <AppAuthenticated/>
            </BrowserRouter>
        );
    }
}

export default withAuthentication(App);
