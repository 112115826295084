// =======================================
//          API constants
// =======================================
export let BASE_API_URL;
export let GENERAL_OPERATIONS_API_URL;
export let SHIPMENT_OPERATIONS_API_URL;

if (process.env.NODE_ENV === 'development') {
    BASE_API_URL                  = 'http://127.0.0.1:8000/';
    GENERAL_OPERATIONS_API_URL    = 'http://127.0.0.1:8000/general_operations';
    SHIPMENT_OPERATIONS_API_URL   = 'http://127.0.0.1:8000/shipments'
} else if (process.env.NODE_ENV === 'production') {
    BASE_API_URL                  = 'https://api.encord.com/';
    GENERAL_OPERATIONS_API_URL    = 'https://api.encord.com/general_operations';
    SHIPMENT_OPERATIONS_API_URL   = 'https://api.encord.com/shipments'
}
