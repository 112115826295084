import React, {useState} from 'react';
import axios from "axios";
import {Button, Col, Input, message, Radio, Result, Row, Select, Steps, Table, Typography} from 'antd';
import {CheckCircleTwoTone, EditOutlined,} from '@ant-design/icons';
import {v4 as uuidv4} from 'uuid';

import withAuthorization from './session/withAuthorization';
import {deepCopy, DISTRIBUTION_LIST, DISTRIBUTION_LOCATIONS, isUploadingInProgress, OCR_METHODS} from "./AppConstants";
import UploadToGCP from "./UploadToGCP";
import {GENERAL_OPERATIONS_API_URL, SHIPMENT_OPERATIONS_API_URL} from "./APIConstants";

const {Step} = Steps;
const {Option} = Select;
const {Text} = Typography;
const {Column} = Table;

function checkIfDuplicateExists(w){
    return new Set(w).size !== w.length
}

function PostSKUPage(props) {
    const [step, setStep] = useState(0);
    const [destination, setDestination] = useState(0);
    const [editSerialNumber, setEditSerialNumber] = useState(null)
    const [fileList, setFileList] = useState([])
    const [invoiceImage, setInvoiceImage] = useState([])
    const [invoicePicture, setInvoicePicture] = useState([])
    const [invoiceNumber, setInvoiceNumber] = useState(null)
    const [processingOCR, setProcessingOCR] = useState(false)
    const [OCRCompleted, setOCRCompleted] = useState(false)
    const [shipmentSubmitted, setShipmentSubmitted] = useState(false)
    const [submittingShipment, setSubmittingShipment] = useState(false)
    const [oldOrNew, setOldOrNew] = useState("1")
    const [includeSerialNumber, setIncludeSerialNumber] = useState("0")
    const {authUser} = props;

    // ===============================
    //          Distribution list
    // ===============================
    const getDistributionList = () => {
        if (authUser.distribution_location === DISTRIBUTION_LOCATIONS.CHINA) {
            return DISTRIBUTION_LIST.CHINA;
        } else if (authUser.distribution_location === DISTRIBUTION_LOCATIONS.IRVINE) {
            return DISTRIBUTION_LIST.IRVINE;
        }
    }

    const distributionList = getDistributionList();

    // ===============================
    //          Upload logic
    // ===============================
    const uploadInProgress =
        isUploadingInProgress(fileList)
        || isUploadingInProgress(invoiceImage)
        || isUploadingInProgress(invoicePicture);

    // ===============================
    //  OCR processing logic for invoice number
    // ===============================
    const runOCRForInvoiceNumber = () => {
        message.info('Processing image')
        const data = packageInvoiceNumberForOCR()
        console.log(data)
        setProcessingOCR(true)
        axios.post(`${GENERAL_OPERATIONS_API_URL}/ocr`, data)
            .then((res) => {
                console.log(res)
                const _res = res.data[0];
                _res.ocr_str.forEach((ocrStr) => {
                    if (ocrStr.length === 5 && !isNaN(ocrStr)) setInvoiceNumber(ocrStr)
                })
                message.success('Image processed')
                setProcessingOCR(false)
            }).catch(() => {
        });
    }

    const packageInvoiceNumberForOCR = () => {
        return {
            ocr_method: OCR_METHODS.GOOGLE_VISION,
            images: getInvoiceNumberImages()
        }
    }

    const getInvoiceNumberImages = () => {
        const images = []
        invoiceImage.forEach((image) => {
            images.push({
                image_hash: image.image_hash,
                file_link: image.file_link,
            })
        })
        return images
    }

    // ===============================
    //  OCR processing logic for serial numbers
    // ===============================
    const runOCR = () => {
        message.info('Processing images')
        setProcessingOCR(true)
        const data = packageFileListForOCR()
        axios.post(`${GENERAL_OPERATIONS_API_URL}/ocr`, data, { withCredentials: true })
            .then((res) => {
                const _res = res.data;
                const _fileList = []
                const serialNumbers = parseSerialNumbers(_res)

                // Check if duplicates exist
                const __serialNumbers = []
                Object.keys(serialNumbers).forEach((key) => {
                    __serialNumbers.push(serialNumbers[key])
                })

                if(checkIfDuplicateExists(__serialNumbers)) {
                    message.error('You have a duplicate serial number - please double check!')
                }

                fileList.forEach((file) => {
                    if (Object.prototype.hasOwnProperty.call(serialNumbers, file.image_hash)) {
                        file.serial_no = serialNumbers[file.image_hash]
                    } else {
                        file.serial_no = ['000000']
                    }
                    _fileList.push(file)
                })
                setFileList(_fileList)
                message.success('Images processed')
                setProcessingOCR(false)
                setOCRCompleted(true)
            }).catch(() => {
        });
    }

    const parseSerialNumbers = (res) => {
        const serialNumbers = {}
        res.forEach(element => {
            element.ocr_str.forEach(resOCRStr => {
                resOCRStr = resOCRStr.trim()
                const { image_hash } = element;
                if (oldOrNew === "0" && resOCRStr.length === 6 && isValidOldSerialNumber(resOCRStr)) {
                    if (resOCRStr[0] === 'O' || resOCRStr[0] === 'О') {
                        const parsedOCRStr = '0' + resOCRStr[1] + resOCRStr[2] + resOCRStr[3] + resOCRStr[4] + resOCRStr[5];
                        if (Object.prototype.hasOwnProperty.call(serialNumbers, image_hash)) {
                            serialNumbers[element.image_hash].push(parsedOCRStr)
                        } else {
                            serialNumbers[element.image_hash] = [parsedOCRStr];
                        }
                    } else {
                        if (Object.prototype.hasOwnProperty.call(serialNumbers, image_hash)) {
                            serialNumbers[element.image_hash].push(resOCRStr)
                        } else {
                            serialNumbers[element.image_hash] = [resOCRStr]
                        }
                    }
                }
                if (oldOrNew === "1" && resOCRStr.length === 7 && isValidNewSerialNumber(resOCRStr)) {

                    const parsedSerialNumber = parseNewSerialNumber(resOCRStr);

                    if (Object.prototype.hasOwnProperty.call(serialNumbers, image_hash)) {
                        serialNumbers[element.image_hash].push(parsedSerialNumber)
                    } else {
                        serialNumbers[element.image_hash] = [parsedSerialNumber]
                    }
                }
            })
        });

        // Remove duplicates
        for (const [key, value] of Object.entries(serialNumbers)) {
            serialNumbers[key] = [...new Set(serialNumbers[key])]
        }

        return serialNumbers
    }

    const isValidOldSerialNumber = (serialNo) => {
        let letter = serialNo[1]
        let firstNumber = serialNo[2]
        let secondNumber = serialNo[3]
        let thirdNumber = serialNo[4]
        let fourthNumber = serialNo[5]

        if (letter === 'В') letter = 'B'
        if (firstNumber === 'O' || firstNumber === 'О') firstNumber = 0
        if (secondNumber === 'O' || secondNumber === 'О') secondNumber = 0
        if (thirdNumber === 'O' || thirdNumber === 'О') thirdNumber = 0
        if (fourthNumber === 'O' || fourthNumber === 'О') fourthNumber = 0

        const letters = letter
        const numbers = firstNumber + secondNumber + thirdNumber + fourthNumber

        return letters.match(/^[A-Za-z]+$/)
            && !isNaN(numbers);
    }

    const isValidNewSerialNumber = (serialNo) => {
        let firstLetter = serialNo[0]
        let secondLetter = serialNo[1]
        let thirdLetter = serialNo[2]

        let firstNumber = serialNo[3]
        let secondNumber = serialNo[4]
        let thirdNumber = serialNo[5]
        let fourthNumber = serialNo[6]

        if (firstLetter === 'В') firstLetter = 'B'
        if (secondLetter === 'В') secondLetter = 'B'
        if (thirdLetter === 'В') thirdLetter = 'B'

        if (firstNumber === 'O' || firstNumber === 'О') firstNumber = 0
        if (secondNumber === 'O' || secondNumber === 'О') secondNumber = 0
        if (thirdNumber === 'O' || thirdNumber === 'О') thirdNumber = 0
        if (fourthNumber === 'O' || fourthNumber === 'О') fourthNumber = 0

        const letters = firstLetter + secondLetter + thirdLetter
        const numbers = firstNumber + secondNumber + thirdNumber + fourthNumber

        return letters.match(/^[A-Za-z]+$/)
            && !isNaN(numbers);
    }

    const parseNewSerialNumber = (serialNo) => {
        let firstLetter = serialNo[0]
        let secondLetter = serialNo[1]
        let thirdLetter = serialNo[2]

        let firstNumber = serialNo[3]
        let secondNumber = serialNo[4]
        let thirdNumber = serialNo[5]
        let fourthNumber = serialNo[6]

        if (firstLetter === 'В') firstLetter = 'B'
        if (secondLetter === 'В') secondLetter = 'B'
        if (thirdLetter === 'В') thirdLetter = 'B'

        if (firstNumber === 'O' || firstNumber === 'О') firstNumber = 0
        if (secondNumber === 'O' || secondNumber === 'О') secondNumber = 0
        if (thirdNumber === 'O' || thirdNumber === 'О') thirdNumber = 0
        if (fourthNumber === 'O' || fourthNumber === 'О') fourthNumber = 0

        const letters = firstLetter + secondLetter + thirdLetter
        const numbers = firstNumber + secondNumber + thirdNumber + fourthNumber

        return letters + numbers;
    }

    const packageFileListForOCR = () => {
        return {
            ocr_method: OCR_METHODS.GOOGLE_VISION,
            images: getOCRImages()
        }
    }

    const getOCRImages = () => {
        const images = []
        fileList.forEach((image) => {
            images.push({
                image_hash: image.image_hash,
                file_link: image.file_link,
            })
        })
        return images
    }

    const changeSerialNumber = (value, imageHash, i) => {
        let _fileList = deepCopy(fileList);
        const arrayIndex = fileList.findIndex(_file => _file.image_hash === imageHash);
        _fileList[arrayIndex].serial_no[i] = value
        setFileList(_fileList)
    }

    const shouldNextBeDisabled = () => {
        if (uploadInProgress || processingOCR) return true;
        else if (includeSerialNumber === "0" && fileList.length === 0) return true;
        else if (includeSerialNumber === "0" && fileList.length > 0 && !OCRCompleted) return true
        return false
    }

    // ===============================
    //      Submit details to DB
    // ===============================
    const submitShipmentDetails = () => {
        setSubmittingShipment(true)
        message.info('Submitting shipment')
        const data = packageDataForDB()
        axios.post(`${SHIPMENT_OPERATIONS_API_URL}/insert`, data, { withCredentials: true })
            .then((res) => {
                if (res.data.status) {
                    setSubmittingShipment(false)
                    setShipmentSubmitted(true)
                } else {
                    message.error(res.data.response)
                    setSubmittingShipment(false)
                }
            }).catch(() => {
        });
    }

    const packageDataForDB = () => {
        const shipment = []

        if (includeSerialNumber === "0") {
            fileList.forEach((file) => {
                const { serial_no } = file;
                serial_no.forEach((serialNo) => {
                    shipment.push({
                        "shipment_hash": uuidv4(),
                        "user_hash": authUser.uid,
                        "invoice_number": invoiceNumber,
                        "serial_number": serialNo,
                        "invoice_number_image_hash": invoiceImage[0].image_hash,
                        "serial_number_image_hash": file.image_hash,
                        "invoice_picture_link": invoicePicture.length > 0 ? invoicePicture[0].file_link : '',
                        "destination": distributionList[destination],
                    })
                })
            })
        } else {
            shipment.push({
                "shipment_hash": uuidv4(),
                "user_hash": authUser.uid,
                "invoice_number": invoiceNumber,
                "serial_number": '000000',
                "invoice_number_image_hash": invoiceImage[0].image_hash,
                "serial_number_image_hash": '',
                "invoice_picture_link": invoicePicture.length > 0 ? invoicePicture[0].file_link : '',
                "destination": distributionList[destination],
            })
        }
        return { shipment }
    }

    return (
        <>
            <Row style={{marginTop: '16px'}}>
                <Steps direction="horizontal" size="small" current={step}>
                    <Step title="Details"/>
                    <Step title="Upload"/>
                    <Step title="Submit"/>
                </Steps>
            </Row>
            {step === 0 &&
            <>
                <Row style={{marginTop: '32px'}}>
                    <Text strong>Destination</Text>
                    <Select
                        showSearch
                        placeholder="Select destination"
                        style={{width: '100%'}}
                        optionFilterProp="children"
                        onChange={(_destination) => setDestination(_destination)}
                        value={destination}
                        allowClear
                    >
                        {Object.values(distributionList).map((location, index) => (
                            <Option
                                key={index}
                                value={index}
                            >
                                {location.QUICKBOOKS_NAME} (<b>{location.COMPANY}</b>)
                            </Option>
                        ))}
                    </Select>
                </Row>
                <Row style={{ marginTop: '16px'}}>
                    <Text strong>Old/new serial number system</Text>
                </Row>
                <Row>
                    <Radio.Group value={oldOrNew}>
                        <Radio.Button value="0" onClick={() => setOldOrNew("0")}>Old</Radio.Button>
                        <Radio.Button value="1" onClick={() => setOldOrNew("1")}>New</Radio.Button>
                    </Radio.Group>
                </Row>
                <Row style={{marginTop: '16px'}}>
                    <Text strong>Invoice picture (optional)</Text>
                </Row>
                <Row>
                    <Col span={24}>
                        <UploadToGCP
                            fileList={invoicePicture}
                            setFileList={(picture) => setInvoicePicture(picture)}
                            singleUpload
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '16px'}}>
                    <Text strong>Invoice number</Text>
                </Row>
                <Row>
                    <Col span={24}>
                        <UploadToGCP
                            fileList={invoiceImage}
                            setFileList={(number) => setInvoiceImage(number)}
                            singleUpload
                            pagination={false}
                        />
                    </Col>
                </Row>
                {invoiceImage.length > 0 &&
                <Row justify="space-between" style={{ marginTop: '4px'}} >
                    <Col span={12}>
                        <Text strong>Invoice number:</Text> {invoiceNumber}
                    </Col>
                    <Col span={12}>
                        <div style={{ float: 'right '}}>
                            <Button
                                onClick={() => runOCRForInvoiceNumber()}
                                loading={processingOCR}
                                disabled={uploadInProgress}
                            >
                                Run OCR
                            </Button>
                        </div>
                    </Col>
                </Row>
                }
                <Row style={{marginTop: '32px'}} justify="end">
                    <Button
                        type="primary"
                        onClick={() => setStep(step + 1)}
                        disabled={uploadInProgress || invoiceImage.length === 0 || invoiceNumber === null}
                    >
                        {uploadInProgress ? 'Uploading...' : 'Next'}
                    </Button>
                </Row>
            </>
            }
            {step === 1 &&
            <>
                <Row style={{marginTop: '32px'}}>
                    <Row>
                        <Text strong>Include serial number</Text>
                    </Row>
                </Row>
                <Row>
                    <Radio.Group value={includeSerialNumber}>
                        <Radio.Button value="0" onClick={() => setIncludeSerialNumber("0")}>Yes</Radio.Button>
                        <Radio.Button value="1" onClick={() => setIncludeSerialNumber("1")}>No</Radio.Button>
                    </Radio.Group>
                </Row>
                <Row style={{marginTop: '16px'}}>
                    <Row>
                        <Text strong>Upload images of serial numbers</Text>
                    </Row>
                </Row>
                <Row>
                    <Col span={24}>
                        <UploadToGCP
                            fileList={fileList}
                            setFileList={(list) => setFileList(list)}
                            singleUpload={false}
                            pagination={true}
                        />
                    </Col>
                </Row>
                {fileList.length > 0 &&
                <Row justify="end">
                    <Button
                        onClick={() => runOCR()}
                        disabled={uploadInProgress}
                        loading={processingOCR}
                    >
                        Run OCR
                    </Button>
                </Row>
                }
                <Row style={{marginTop: '32px'}} justify="space-between">
                    <Button onClick={() => setStep(step - 1)}>
                        Go back
                    </Button>
                    <Button
                        type="primary"
                        disabled={shouldNextBeDisabled()}
                        onClick={() => setStep(step + 1)}
                    >
                        {uploadInProgress ? 'Uploading...' : 'Next'}
                    </Button>
                </Row>
            </>
            }
            {step === 2 &&
            <>
                {shipmentSubmitted ?
                    <>
                        <Result
                            status="success"
                            title="Successfully Submitted Shipment!"
                            subTitle={"Invoice Number: " + invoiceNumber}
                            extra={[
                                <Button type="primary" key="console" onClick={() => window.location.reload(false)}>
                                    Submit Other Shipment
                                </Button>
                            ]}
                        />
                    </>
                    :
                    <>
                        <Row style={{marginTop: '32px'}}>
                            <Text strong>Invoice number</Text>
                        </Row>
                        <Row>
                            {invoiceNumber}
                        </Row>
                        <Row style={{marginTop: '16px'}}>
                            <Text strong>Processed serial numbers</Text>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table
                                    rowKey="image_hash"
                                    dataSource={fileList}
                                    pagination={{defaultPageSize: 10}}
                                    size="small"
                                >
                                    <Column
                                        title="File"
                                        dataIndex="title"
                                        width='40%'
                                    />
                                    <Column
                                        title="Serial #"
                                        render={(item) => (
                                            <>
                                                {editSerialNumber && item.image_hash === editSerialNumber ?
                                                    <>
                                                        {item.serial_no.map((serialNo, i) => (
                                                            <Input
                                                                key={serialNo}
                                                                value={serialNo}
                                                                onChange={(e) => changeSerialNumber(e.target.value, item.image_hash, i)}
                                                            />
                                                        ))}
                                                    </>
                                                    :
                                                    item.serial_no.map((serialNo) => (
                                                        <span key={serialNo}>
                                                            {serialNo}<br/>
                                                        </span>
                                                    ))
                                                }
                                            </>
                                        )}
                                        width='40%'
                                    />
                                    <Column
                                        dataIndex="image_hash"
                                        render={(image_hash) => (
                                            <>
                                                {editSerialNumber && editSerialNumber === image_hash ?
                                                    <Button
                                                        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                                                        onClick={() => setEditSerialNumber(null)}
                                                    />
                                                    :
                                                    <Button
                                                        icon={<EditOutlined/>}
                                                        onClick={() => setEditSerialNumber(image_hash)}
                                                    />
                                                }
                                            </>
                                        )}
                                        width='20%'
                                    />
                                </Table>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '32px'}} justify="space-between">
                            <Button onClick={() => setStep(step - 1)}>
                                Go back
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => submitShipmentDetails()}
                                loading={submittingShipment}
                            >
                                Submit
                            </Button>
                        </Row>
                    </>
                }
            </>
            }
        </>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(PostSKUPage);
